// @flow strict
import { Link } from "gatsby";
import React from "react";
import {
  menu as menuStyle,
  menu__list,
  menu__list_item,
  menu__list_item_link,
  menu__list_item_link__active,
} from "./Menu.module.scss";

type Props = {
  menu: {
    label: string,
    path: string,
  }[],
};

const Menu = ({ menu }: Props) => (
  <nav className={menuStyle}>
    <ul className={menu__list}>
      {menu.map((item) => (
        <li className={menu__list_item} key={item.path}>
          <Link
            to={item.path}
            className={menu__list_item_link}
            activeClassName={menu__list_item_link__active}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default Menu;
