// @flow strict
import React from "react";
import { useSiteMetadata } from "../../hooks";
import Author from "./Author";
import Contacts from "./Contacts";
import Menu from "./Menu";
import { sidebar, sidebar__inner } from "./Sidebar.module.scss";

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, menu } = useSiteMetadata();

  return (
    <div className={sidebar}>
      <div className={sidebar__inner}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <Contacts contacts={author.contacts} />
      </div>
    </div>
  );
};

export default Sidebar;
