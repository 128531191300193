import React from "react";
import { page, page__body, page__inner, page__title } from "./Page.module.scss";

type Props = {
  title?: string,
  children: React.Node,
};

const Page = ({ title, children }: Props) => {
  return (
    <div className={page}>
      <div className={page__inner}>
        {title && <h1 className={page__title}>{title}</h1>}
        <div className={page__body}>{children}</div>
      </div>
    </div>
  );
};

export default Page;
